<template>
  <NoActiveModal
    v-if="ready && !primaryPropositionActive"
    :payment-page-proposition-id="primaryPropositionId"
    :in-free-area="primaryPropositionFreeArea"
    source="swap-list"
  />
  <div v-else class="matches-page-container">
    <div class="matches-page">
      <template v-if="isLoadingStatusCounts">
        <div class="loading-container-page">
          <BaseSpinnerAndText />
        </div>
      </template>
      <template v-else>
        <template v-if="!(isMobile && selectedMatch)">
          <MatchesNavigation class="tabs" tab-bg-color="transparent" />
          <div
            v-if="!isLoadingMatches"
            ref="matchesContainer"
            :class="[
              'matches-container',
              { 'matches-container--no-matches': !tabHasMatches }
            ]"
          >
            <NoResult v-if="!tabHasMatches" />
            <template v-else>
              <div
                v-if="
                  activeTab === 'unanswered' && matchesInActiveTab.length >= 3
                "
                class="blocked-banner"
              >
                <BaseIcon
                  icon-file="chat-unlock-blue"
                  :width="isMobile ? 24 : 32"
                  :height="isMobile ? 24 : 32"
                  class="icon"
                />
                <div>
                  <strong>{{ $t('blocked_banner_title') }}</strong>
                  <p>
                    {{ $t('blocked_banner_text') }}
                  </p>
                </div>
              </div>
              <MatchCard
                v-for="(match, index) in matchesInActiveTab"
                :key="index"
                :match="match"
                :selected-match="selectedMatch"
                @click.native="handleMatchSelected(match)"
              />
            </template>
          </div>
          <div v-else :class="['loading-container', { panel: !isMobile }]">
            <BaseSpinnerAndText />
          </div>
        </template>
        <div
          v-if="
            (tabHasMatches && selectedMatch) ||
            isLoadingMatches ||
            (primaryPropositionIsLimited && !isMobile && tabHasMatches)
          "
          class="chat-rooms-container"
        >
          <Messages
            v-if="selectedMatch && selectedMatch.chatRoom"
            :key="selectedMatch.id"
            :in-room="selectedMatch.chatRoom"
            :user-id="user.userId"
            :is-impersonated="user.isImpersonated"
            :is-mobile="isMobile"
            :standalone="false"
            :used-in-matches="true"
            :match-is-inactive="swapIsInactive"
            where="matches"
            @room-created="chatRoomCreated"
            @chat-read="resetReadCount(selectedMatch)"
            @close-chat-clicked="closeChat"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { subscribe, unsubscribe } from '../../utils/socket';

import MatchCard from '@/components/Matches/MatchCard.vue';
import MatchesNavigation from '@/components/Matches/MatchesNavigation.vue';
import NoResult from '@/components/Matches/NoResult.vue';
import Messages from '@/views/Chat/Messages';
import NoActiveModal from '@/components/Modals/NoActiveModal';
import events from '../../utils/helpers/events';

export default {
  name: 'Matches',

  components: {
    MatchCard,
    Messages,
    MatchesNavigation,
    NoResult,
    NoActiveModal
  },

  data() {
    return {
      selectedMatch: null,
      scrollPosition: 0
    };
  },

  head: {
    title() {
      return {
        inner: 'Matches'
      };
    }
  },

  computed: {
    ...mapGetters({
      activeTab: 'matches/activeTab',
      matchesInActiveTab: 'matches/matchesInActiveTab',
      isLoadingMatchesPage: 'matches/isLoadingMatchesPage',
      isLoadingStatusCounts: 'matches/isLoadingStatusCounts',
      isLoadingMatches: 'matches/isLoadingMatches',
      isMobile: 'screenSize/isMobile',
      user: 'app/user',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited',
      ready: 'app/ready',
      primaryPropositionActive: 'myPropositions/primaryPropositionActive',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryPropositionFreeArea: 'myPropositions/primaryPropositionFreeArea'
    }),

    tabHasMatches() {
      return this.matchesInActiveTab && this.matchesInActiveTab.length > 0;
    },

    swapIsInactive() {
      let isInactivated = false;
      if (this.selectedMatch.lastMessage?.senderName === 'SYSTEM') {
        const parsedContent = JSON.parse(
          this.selectedMatch.lastMessage.message
        );
        isInactivated = parsedContent.type === 'INTEREST_LEVEL_3';
      }

      return this.activeTab === 'inactive' || isInactivated;
    }
  },

  watch: {
    selectedMatch(match) {
      if (match) {
        this.handleSeenUpdatedMatch(match);

        const newPath = this.$routes.MATCHES + '/' + match.id;
        const currentPath = window.location.pathname;

        if (newPath === currentPath) {
          return;
        }

        if (this.isMobile) {
          this.$router.push({
            path: newPath,
            query: this.$route.query
          });
        } else {
          this.$router.replace({
            path: newPath,
            query: this.$route.query
          });
        }
      }
    },

    $route(to, from) {
      if (from.params.id && this.isMobile) {
        this.selectedMatch = null;
        window.scrollTo(0, this.scrollPosition);
      }
    },

    matchesInActiveTab() {
      this.selectMatch();
    },

    activeTab() {
      this.$nextTick(() => {
        if (this.$refs.matchesContainer) {
          this.$refs.matchesContainer.scrollTop = 0;
        }
      });
    }
  },

  created() {
    window.scrollTo(0, 0);

    if (this.primaryPropositionActive) {
      this.init({
        matchId: this.$route.params.id
      });
    }

    subscribe('chat-message-sent', this.onChatMessageSent);
  },

  mounted() {
    if (!this.isMobile) this.addDisableScroll('matches-page');
  },

  beforeDestroy() {
    unsubscribe('chat-message-sent', this.onChatMessageSent);
  },

  destroyed() {
    if (!this.isMobile) this.removeDisableScroll('matches-page');
  },

  methods: {
    ...mapActions({
      readUpdatedMatch: 'matches/readUpdatedMatch',
      init: 'matches/init',
      temporaryHideTrialBanner: 'trial/temporaryHideTrialBanner',
      setGlobalModal: 'ui/setGlobalModal',
      refetchSingleMatch: 'matches/refetchSingleMatch'
    }),

    ...mapMutations({
      updateLastMessage: 'matches/updateLastMessage',
      resetReadCount: 'matches/resetReadCount',
      addDisableScroll: 'ui/addDisableScroll',
      removeDisableScroll: 'ui/removeDisableScroll'
    }),

    onChatMessageSent(data) {
      this.updateLastMessage(data);
    },

    async handleMatchSelected(match) {
      // Show paywall if primary proposition is limited (trial / freemium)
      if (this.primaryPropositionIsLimited) {
        this.setGlobalModal('ChatLocked');
        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: 'open-matches-chat',
          src: 'matches'
        });
        return;
      }

      this.selectedMatch = match;
      this.scrollPosition = window.scrollY;
    },

    closeChat() {
      this.$router.replace({ path: this.$routes.MATCHES });
      this.selectedMatch = null;
      window.scrollTo(0, this.scrollPosition);
    },

    async chatRoomCreated() {
      const match = await this.refetchSingleMatch({
        myPropositionId: this.primaryPropositionId,
        matchId: this.selectedMatch.id
      });

      this.selectedMatch = match;
    },

    async handleSeenUpdatedMatch(match) {
      if (match.isNew) {
        await this.readUpdatedMatch(match.id);
      }
    },

    selectMatch() {
      // Select match from path param, otherwise first match (on desktop)
      if (
        !this.primaryPropositionIsLimited &&
        this.matchesInActiveTab &&
        this.matchesInActiveTab.length > 0
      ) {
        let matchFromPath;
        if (this.$route.params.id) {
          matchFromPath = this.matchesInActiveTab.find(
            match => match.id === this.$route.params.id
          );
        }
        if (this.isMobile) {
          this.handleMatchSelected(matchFromPath, false);
        } else {
          this.handleMatchSelected(matchFromPath || this.matchesInActiveTab[0]);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.matches-page-container {
  max-width: none;
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f9;

  @media ($desktop) {
    padding-top: 80px;
    padding-bottom: 0;
  }
}

.matches-page {
  @media ($desktop) {
    display: grid;
    grid-template-columns: 472px minmax(0, 1fr);
    grid-template-rows: min-content auto;
    max-width: 1448px; // 1440px + padding 24px * 2
    height: calc(100vh - 80px);
    margin: 0 auto;
    column-gap: 24px;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 24px;

    .is-freemium & {
      height: calc(100vh - 80px - 70px);
    }

    .is-trial & {
      height: calc(100vh - 80px - 103px);
    }
  }

  @media (max-width: 1024px) and (min-width: 769px) {
    grid-template-columns: 350px minmax(0, 1fr);
  }
}

.tabs {
  grid-column: 1 / -1;
}

.matches-container {
  padding: 10px;

  @media ($desktop) {
    padding: 24px;
    background-color: #fff;
    border-radius: 16px;
    border: 1px solid #ebebeb;
    box-shadow: 0px 0px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    overflow-y: scroll;

    // Scrollbar styling
    scrollbar-color: #b7b7b7 transparent;
    &::-webkit-scrollbar-thumb {
      background: #b7b7b7;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}

.matches-container--no-matches {
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
}

.chat-rooms-container {
  background-color: #fff;
  overflow: hidden;

  @media ($mobile) {
    min-height: 90vh;
  }

  @media ($desktop) {
    height: 100%;
    border-radius: 16px;
    border: 1px solid #ebebeb;
    box-shadow: 0px 0px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  }

  &.no-selected {
    display: grid;
    justify-content: center;
    padding-top: 24px;
  }
}

.loading-container-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-container {
  display: grid;
  place-items: center;

  @media ($mobile) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.panel {
  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
}

.blocked-banner {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 15px;
  background: #ffffff;
  border: 2px solid #e8e8e8;
  border-radius: 10px;
  font-size: 14px;
  line-height: 1.4;
  color: $text-primary;

  @media ($desktop) {
    margin-bottom: 24px;
  }

  .icon {
    display: grid;
    place-items: center;
    margin-right: 15px;
    flex-shrink: 0;
  }

  p,
  strong {
    margin: 0;
  }

  strong {
    font-weight: 600;
    line-height: 1.2;
    display: inline-block;
    margin-bottom: 2px;
  }
}
</style>
