<template>
  <div class="full-swap-modal-wrapper">
    <StandardModal
      standard-modal-name="FullSwapModal"
      content-height="max-content"
      :esc-close="true"
      :show-header="false"
      header-title="Sort Options"
      overlay-type="gradient"
      slide-up-transition="tut-slide-up"
      @close="setGlobalModal(false)"
    >
      <div slot="content" class="full-swap-modal-content">
        <FullSwapModalContent @close="setGlobalModal(false)" />
      </div>
    </StandardModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import StandardModal from '@/components/Modals/Standard';
import FullSwapModalContent from '@/components/FullSwap/FullSwapModalContent';

export default {
  name: 'FullSwapModalWrapper',

  components: {
    StandardModal,
    FullSwapModalContent
  },

  methods: {
    ...mapActions({
      setGlobalModal: 'ui/setGlobalModal'
    }),

    close() {
      this.setGlobalModal(false);
    }
  }
};
</script>

<style lang="scss" scoped>
.full-swap-modal-wrapper {
  .full-swap-modal-content {
    min-height: 300px;
  }
}
</style>
