<template>
  <NoActiveModal
    v-if="ready && firstLoadDone && !primaryPropositionActive"
    :payment-page-proposition-id="primaryPropositionId"
    :in-free-area="primaryPropositionFreeArea"
    source="swap-list"
  />
  <div
    v-else
    class="swap-list-wrapper"
    :class="{
      'map-mode': swapListType === SWAP_LIST_TYPES.MAP
    }"
  >
    <ContextualTutorial
      v-if="shouldShowTutorial && hasEnoughSwaps"
      :esc-close="escCloseTut"
      :show-tutorial="showTutorial"
      @completed="tutorialCompleted"
    />

    <!-- <SwapTutorial :show="false" @completed="tutorialCompleted" /> -->

    <template>
      <AccountNotificationModal
        :show="showAccountNotificationModal"
        @cta-click="handleNotificationCtaClick"
        @close="handleCloseNotification"
      />
      <SwapTabNavigation tab-bg-color="transparent" />
      <div class="content-wrapper">
        <div
          v-if="isMobile && swaps && swaps.length > 0"
          class="floating-container"
        >
          <transition name="fade-text" mode="out-in" appear>
            <MapButton
              v-if="showMapButton && swapListType === SWAP_LIST_TYPES.LIST"
              class="list"
              @click="goToMap"
            />

            <ListButton
              v-if="swapListType === SWAP_LIST_TYPES.MAP"
              class="map"
              @click="goToList"
            />
          </transition>
        </div>

        <MobileSwapHeader
          v-if="isMobile && swapListType === SWAP_LIST_TYPES.LIST"
          :hidden="showContextualTutorial"
        />
        <div
          v-if="!isMobile"
          class="desktop-filter-wrapper"
          :class="{ 'map-mode': swapListType === SWAP_LIST_TYPES.MAP }"
        >
          <StickySidebar>
            <div
              v-if="swapListType === SWAP_LIST_TYPES.LIST"
              class="filter-title"
            >
              {{ $t('swap_list_search_title') }}
            </div>

            <Search v-if="swapListType === SWAP_LIST_TYPES.LIST" />

            <div v-if="!distributedSwapsEnabled" class="filter-title">
              {{ $t('swap_list_filter_title') }}
            </div>

            <div class="filter-box">
              <Filters />
            </div>
          </StickySidebar>
        </div>
        <div
          :class="[
            'list-content',
            { 'map-mode': swapListType === SWAP_LIST_TYPES.MAP }
          ]"
        >
          <InfoBanner
            v-if="!isMobile && swapListType === SWAP_LIST_TYPES.LIST"
            class="info-banner"
          />

          <div
            v-if="
              !isMobile &&
              !shouldShowTutorial &&
              shouldShowOnboarding &&
              swapListType !== SWAP_LIST_TYPES.MAP
            "
            class="onboarding-wrapper"
            :class="{
              'onboarding-fade-grow-animation':
                tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED
            }"
          >
            <OnboardingBanner />
          </div>

          <SwapFilterButtons v-if="isMobile" :is-loading="loading" />

          <SwapHeader
            v-if="!isMobile && swapListType === SWAP_LIST_TYPES.LIST"
            :show-filters="swapListType === SWAP_LIST_TYPES.LIST"
          />

          <div v-if="swapListType === SWAP_LIST_TYPES.MAP">
            <MapContainer />
          </div>

          <LoaderContainer
            v-else-if="
              !distributedSwapsEnabled &&
              (loading || isMyPropositionsLoading || !firstLoadDone)
            "
            :is-mobile="isMobile"
            :progress="progress"
            :swap-item-mode="swapItemMode"
            :current-page-size="currentPageSize"
          />

          <LoaderContainer
            v-else-if="distributedSwapsEnabled && !firstLoadDone"
            :is-mobile="isMobile"
            :progress="progress"
            :swap-item-mode="swapItemMode"
            :current-page-size="currentPageSize"
          />

          <NoResult
            v-else-if="
              !distributedSwapsEnabled &&
              !loading &&
              !isMyPropositionsLoading &&
              swaps &&
              swaps.length === 0
            "
          />

          <ReturnLaterMessage
            v-else-if="
              distributedSwapsEnabled &&
              !loading &&
              !isMyPropositionsLoading &&
              swaps &&
              swaps.length === 0
            "
            :distributed="postFilterCountWithDiffs"
            :undistributed="undistributedCount"
          />

          <div v-else>
            <SwapContainer
              v-if="
                isMobile ||
                (!isMobile && swapItemMode === SWAP_ITEM_TYPES.CARDS) ||
                isUnansweredNewSwapFilterType
              "
              :ref="'swap-container-ref'"
              :should-blur-last-swaps="shouldBlurLastSwaps"
            />
            <SwapListContainer
              v-else
              :should-blur-last-swaps="shouldBlurLastSwaps"
            />

            <NextNewSwapButton
              v-if="
                distributedSwapsEnabled &&
                swapItemMode === SWAP_ITEM_TYPES.CARDS
              "
            ></NextNewSwapButton>

            <div v-if="showUpdateWishLink" class="adjust-wishes">
              <h3>{{ $t('swap_container_title') }}</h3>
              <p class="adjust-wishes-action">
                {{ $t('swap_container_action') }}
              </p>
              <BaseButton
                :link="`${$routes.EDIT_PROPOSITION}/${primaryPropositionId}`"
                >{{ $t('swap_container_button') }}</BaseButton
              >
            </div>

            <ReturnLaterMessage
              v-if="distributedSwapsEnabled && showReturnLaterMessage"
              :distributed="postFilterCountWithDiffs"
              :undistributed="undistributedCount"
            />

            <ReturnLaterModal
              v-if="distributedSwapsEnabled && showReturnLaterModal"
              @close-return-later-modal="handleReturnLaterModalClose"
            />

            <FlowPagination
              v-if="!distributedSwapsEnabled && totalPages > 1"
              :current-page="currentPage"
              :max-page="totalPages"
              :on-page-click="handlePageClick"
              :item-count="postFilterCountWithDiffs"
              :current-page-size="currentPageSize"
              :on-select-page-size="handlePageSizeSelected"
            />
            <InfiniteScrollPagination
              v-if="distributedSwapsEnabled && hasMore"
              :ref="'infinite-scroll-ref'"
              :on-page-click="() => handlePageClick(currentPage + 1, 1)"
              :is-loading="loading"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import smoothScroll from '@/utils/smoothScroll';
import {
  SWAP_LIST_TYPES,
  SWAP_ITEM_TYPES,
  SORT_TYPES,
  SWAP_FILTER_TYPES
} from '@/store/modules/swapList';

import ContextualTutorial from '@/views/ContextualTutorial/ContextualTutorial.vue';
import SwapHeader from '@/components/SwapList/Header';
import SwapContainer from '@/components/SwapList/SwapContainer';
import SwapListContainer from '@/components/SwapList/SwapListContainer';
import FlowPagination from '@/components/Pagination/FlowPagination';
import InfiniteScrollPagination from '@/components/Pagination/InfiniteScrollPagination';
import MapContainer from '@/components/SwapList/MapContainer';
import Filters from '@/components/SwapList/Filter/Filters';
import ReturnLaterMessage from '@/components/SwapList/ReturnLaterMessage';
import ReturnLaterModal from '@/components/SwapList/ReturnLaterModal';
import NoResult from '@/components/Swaps/NoResult';
import LoaderContainer from '@/components/SwapList/Loading/LoaderContainer';
import Search from '@/components/SwapList/Search';
import MobileSwapHeader from '@/components/SwapList/MobileHeader';
import OnboardingBanner from '@/components/Onboarding/OnboardingBanner';
import NoActiveModal from '@/components/Modals/NoActiveModal';
import StickySidebar from '@/components/StickySidebar';
import NextNewSwapButton from '@/components/SwapList/NextNewSwapButton';
import { lbApiBeta } from '../../utils/axiosConfig';
import InfoBanner from '@/components/InfoBanner';
import events from '@/utils/helpers/events';
import { subscribe, unsubscribe } from '../../utils/socket';
import { FETCH_MODE } from '../../store/modules/swapList';
import { TUTORIAL_STATUS_TYPES } from '../../store/modules/tutorial';
import SwapFilterButtons from '@/components/SwapList/SwapFilterButtons';
import MapButton from '@/components/Buttons/MapButton';
import ListButton from '../../components/Buttons/ListButton';
import SwapTabNavigation from '@/components/SwapList/SwapTabNavigation';
import AccountNotificationModal from '@/components/Modals/AccountNotificationModal.vue';
// import SwapTutorial from '@/components/Tutorial/SwapTutorial/SwapTutorial';
import { debounce } from 'lodash';

export default {
  name: 'SwapList',

  components: {
    SwapHeader,
    SwapContainer,
    SwapListContainer,
    FlowPagination,
    MapContainer,
    Filters,
    ReturnLaterMessage,
    ReturnLaterModal,
    NoResult,
    LoaderContainer,
    Search,
    MobileSwapHeader,
    OnboardingBanner,
    NoActiveModal,
    StickySidebar,
    ContextualTutorial,
    InfoBanner,
    NextNewSwapButton,
    InfiniteScrollPagination,
    SwapFilterButtons,
    MapButton,
    ListButton,
    SwapTabNavigation,
    AccountNotificationModal
    // SwapTutorial
  },

  data() {
    return {
      SWAP_LIST_TYPES,
      SWAP_ITEM_TYPES,
      SORT_TYPES,
      TUTORIAL_STATUS_TYPES,
      currentPageSize: 0,
      waitForAppReadyIntervalId: null,
      firstLoadDone: false,
      progress: 0,
      showTutorial: false,
      showAccountNotificationModal: false,
      reachedBottom: false,
      hasReachedBottom: false
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('meta_title_swaplist')
      };
    }
  },

  computed: {
    ...mapGetters({
      ready: 'app/ready',
      user: 'app/user',
      isMyPropositionsLoading: 'myPropositions/loadingPropositions',
      myPropositions: 'myPropositions/propositions',
      swapItemMode: 'swapList/swapItemMode',
      isMobile: 'screenSize/isMobile',
      loading: 'swapList/loading',
      swapListType: 'swapList/swapListType',
      currentPage: 'swapList/currentPage',
      hasMore: 'swapList/hasMore',
      totalPages: 'swapList/totalPages',
      listPropositionId: 'swapList/listPropositionId',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      sortType: 'swapList/sortType',
      swaps: 'swapList/swaps',
      address: 'swapList/address',
      postFilterCountWithDiffs: 'swapList/postFilterCountWithDiffs',
      swapView: 'swapList/swapView',
      shouldShowOnboarding: 'myPropositions/onboardingState',
      primaryPropositionActive: 'myPropositions/primaryPropositionActive',
      primaryPropositionFreeArea: 'myPropositions/primaryPropositionFreeArea',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited',
      swapFilterType: 'swapList/swapFilterType',
      filterCount: 'swapList/filterCount',
      searchTerm: 'swapList/searchTerm',
      showContextualTutorial: 'tutorial/showContextualTutorial',
      escCloseTut: 'tutorial/escClose',
      undistributedCount: 'swapList/undistributedCount',
      tutStatus: 'tutorial/status',
      swapInterestCountsUnseenTotal: 'swapList/swapInterestCountsUnseenTotal'
    }),

    showMapButton() {
      return !this.reachedBottom;
    },

    hasEnoughSwaps() {
      return this.swaps && this.swaps.length >= 3;
    },

    shouldShowTutorial() {
      return this.primaryPropositionActive && this.showTutorial;
    },

    lastPageAndNoFilter() {
      return (
        this.totalPages === this.currentPage &&
        this.filterCount <= 0 &&
        (!this.searchTerm || this.searchTerm.length <= 0)
      );
    },

    showUpdateWishLink() {
      if (this.lastPageAndNoFilter && this.undistributedCount === 0) {
        return true;
      }

      return false;
    },

    showReturnLaterMessage() {
      if (
        this.lastPageAndNoFilter &&
        this.undistributedCount > 0 &&
        this.swaps.length < 10
      ) {
        return true;
      }

      return false;
    },

    showReturnLaterModal() {
      if (
        this.lastPageAndNoFilter &&
        this.undistributedCount > 0 &&
        this.swaps.length >= 10 &&
        this.hasReachedBottom
      ) {
        return true;
      }

      return false;
    },

    shouldBlurLastSwaps() {
      return (
        this.distributedSwapsEnabled &&
        this.lastPageAndNoFilter &&
        this.undistributedCount > 0 &&
        this.swaps.length >= 10
      );
    },

    isUnansweredNewSwapFilterType() {
      return this.swapFilterType === SWAP_FILTER_TYPES.UNANSWERED_NEW;
    },

    currentPropositionId() {
      return (
        this.$route.params.otherUserPropositionId || this.primaryPropositionId
      );
    },

    distributedSwapsEnabled() {
      return this.$growthbook.isFeatureFlagEnabled('distributed-swaps-2');
    },

    newPaywallsABTest() {
      return this.$growthbook.isFeatureFlagEnabled('new-paywalls');
    }
  },

  watch: {
    currentPropositionId(current, previous) {
      // update swaps if not initial
      if (current != previous && previous && this.primaryPropositionActive) {
        this.getSwaps({ propositionId: current });
      }
    },
    swapItemMode(current) {
      events.emitEvent(events.eventTypes.PAGEVIEW, 'Swap List', {
        propositionId: this.currentPropositionId,
        viewType: this.swapListType,
        listType: current
      });
    },
    swapListType(current) {
      events.emitEvent(events.eventTypes.PAGEVIEW, 'Swap List', {
        propositionId: this.currentPropositionId,
        viewType: current,
        listType: this.swapItemMode
      });
    },
    listPropositionId(current, previous) {
      if (current !== previous) {
        window.scrollTo(0, 0);
      }
    },
    currentPage(current, previous) {
      if (current === 1 && previous > 1) {
        window.scrollTo(0, 0);
      }
    }
  },

  created() {
    // Independent of ready-variable, should be set as fast as possible
    this.initPageSize();
    //this.showMobileTabBar();
    this.temporaryHideTrialBanner({ hide: true });
  },

  async mounted() {
    this.resetFilters({ onPageLoad: true });
    window.addEventListener('scroll', this.onScroll);

    // DEBUG: shows tutorial if set to true
    // this.showTutorial = true;

    this.setSwapType({
      propositionId: this.currentPropositionId,
      swapFilterType: SWAP_FILTER_TYPES.UNANSWERED,
      update: false,
      onPageLoad: true
    });

    try {
      const response = await lbApiBeta.get('/api/user/TutorialCompleted?v=1');

      if (response.data === false) {
        this.showTutorial = true;
      }
    } catch {
      //
    }

    await this.waitForAppReadyAsync();

    subscribe('swaplist', msg => {
      if (msg.progress) {
        this.progress = msg.progress;
      }
    });

    this.$nextTick(() => {
      window.scrollTo(0, 0);
    });

    if (this.primaryPropositionActive) {
      this.progress = 0;
      await this.getSwaps({ propositionId: this.currentPropositionId });
      events.emitEvent(events.eventTypes.PAGEVIEW, 'SwapList', {
        propositionId: this.currentPropositionId,
        viewType: this.swapListType,
        listType: this.swapItemMode
      });
    }

    this.firstLoadDone = true;

    // if user has unseen swap interests, show account notification modal
    this.showAccountNotificationModal = this.swapInterestCountsUnseenTotal > 0;

    window.addEventListener(
      'scroll',
      debounce(this.checkIfScrolledToBottom, 500)
    );
  },

  beforeDestroy() {
    unsubscribe('swaplist');
    window.removeEventListener('scroll', this.onScroll);

    clearInterval(this.waitForAppReadyIntervalId);

    this.temporaryHideTrialBanner({ hide: false });

    window.removeEventListener('scroll', this.checkIfScrolledToBottom);
  },

  methods: {
    ...mapActions({
      getSwaps: 'swapList/getSwaps',
      selectPage: 'swapList/selectPage',
      closeSwapDetails: 'swapList/closeSwapDetails',
      showMobileTabBar: 'menu/showMobileTabBar',
      temporaryHideTrialBanner: 'trial/temporaryHideTrialBanner',
      setSwapType: 'swapList/setSwapType',
      setSwapListType: 'swapList/setSwapListType',
      resetFilters: 'swapList/resetFilters',
      setShowContextualTutorial: 'tutorial/setShowContextualTutorial',
      setGlobalModal: 'ui/setGlobalModal'
    }),

    matchesViewABTest() {
      return this.$growthbook.isFeatureFlagEnabled('matches-view');
    },

    onScroll() {
      if (
        document.body.scrollHeight === window.scrollY + window.innerHeight ||
        window.scrollY + window.innerHeight >= document.body.scrollHeight
      ) {
        this.reachedBottom = true;
      } else {
        this.reachedBottom = false;
      }
    },

    goToMap() {
      if (this.primaryPropositionIsLimited && this.newPaywallsABTest) {
        this.setGlobalModal('MapLocked');

        events.emitEvent(events.eventTypes.SEE, 'Paywall', {
          trigger: 'map-locked',
          src: 'swap-list'
        });
      } else {
        this.setSwapListType({ type: SWAP_LIST_TYPES.MAP });
      }
    },

    goToList() {
      this.reachedBottom = false;
      this.setSwapListType({ type: SWAP_LIST_TYPES.LIST });
    },

    checkIfScrolledToBottom() {
      if (this.loading) return false;
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      this.hasReachedBottom = scrollTop + windowHeight >= documentHeight;
    },

    handleReturnLaterModalClose() {
      this.hasReachedBottom = false;
      smoothScroll({ from: window.scrollY, to: 0 });
    },

    handlePageClick(page, mode) {
      if (page <= 1 || mode !== FETCH_MODE.MERGE) {
        smoothScroll({ from: window.scrollY, to: 0 });
      }

      this.selectPage({
        page,
        mode
      });
    },

    handlePageSizeSelected(pageSize) {
      localStorage.setItem('swap-list_page-size', pageSize);
      this.currentPageSize = pageSize;
      this.handlePageClick(0);
    },

    handleCloseNotification() {
      this.showAccountNotificationModal = false;
    },

    handleNotificationCtaClick() {
      if (this.matchesViewABTest()) {
        this.$router.push(`${this.$t('path_matches', this.$routeLocale)}`);
      } else {
        this.$router.push(`${this.$t('path_messages', this.$routeLocale)}`);
      }
    },

    initPageSize() {
      const savedPageSize =
        localStorage.getItem('swap-list_page-size') || false;
      this.currentPageSize = savedPageSize ? parseInt(savedPageSize) : 25;
    },

    waitForAppReadyAsync() {
      return new Promise(resolve => {
        if (this.waitForAppReadyIntervalId) resolve();

        this.waitForAppReadyIntervalId = setInterval(() => {
          if (this.ready && !this.isMyPropositionsLoading) {
            clearInterval(this.waitForAppReadyIntervalId);
            resolve();
          }
        }, 50);
      });
    },

    tutorialCompleted() {
      this.showTutorial = false;
      if (this.tutStatus === TUTORIAL_STATUS_TYPES.COMPLETED) {
        this.setShowContextualTutorial(false);
      }
      lbApiBeta.post('/api/user/SetTutorialCompleted?v=1');
    }
  }
};
</script>

<style lang="scss" scoped>
.swap-list-wrapper {
  max-width: none;
  padding-bottom: 0;

  @media ($desktop) {
    width: 100%;
    padding-top: $desktop-menu-height;
  }

  &.map-mode {
    @media ($desktop) {
      max-width: none;
      margin: 0;
      width: 100%;
      padding-bottom: 0;
    }
  }
}

.trial-margin-wrapper {
  @media ($desktop) {
    padding-top: calc(
      #{$desktop-menu-height} + 103px
    ); // desktop-menu + trialbanner, sass variable messing up trial state
  }

  @media ($mobile) {
    // padding-top: 0;
    padding-bottom: 190px;
  }
}

.list-content {
  padding: 0 8px;

  @media ($desktop) {
    padding: 0 20px;
    padding-bottom: 55px;
    width: 100%;
    max-width: 75%;
  }

  @media ($mobile) {
    padding: 10px;
    padding-bottom: 95px;
    background-color: #f1f2f6;
  }

  &.map-mode {
    @media ($desktop) {
      padding: 0;
      margin: 0;
      width: calc(100vw - 336px);
      max-width: calc(100vw - 336px);
      height: calc(100vh - #{$desktop-menu-height} - 1px);
    }

    @media ($mobile) {
      padding: 0px;
    }
  }
}

.header {
  font-weight: 700;
  font-size: 1.3rem;
  display: flex;
  color: $text-primary;

  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  &.map-mode {
    @media ($desktop) {
      padding: 0;
      margin: 0;
    }
  }
}

.info-btn {
  border: none;
  outline: none;
  height: 15px;
  width: 15px;
  background-color: white;
  border-radius: 50%;
  background-size: contain;
  background-position: center;
  background-image: url('../../assets/svg/information.svg');
  margin-bottom: 2px;
  margin-left: 5px;
  opacity: 0.5;
}

.desktop-filter-wrapper {
  border-right: 1px solid rgba(28, 45, 65, 0.103);
  width: 25%;
  min-width: 265px;

  &.map-mode {
    @media ($desktop) {
      width: 336px;
      max-width: 336px;
      min-width: 336px;
      height: calc(100vh - #{$desktop-menu-height} - 1px);
      overflow-y: auto;
    }
  }
}

.filter-box {
  padding-right: 15px;
}

.filter-title {
  padding: 20px 12px;
  border-bottom: 1px solid rgba(28, 45, 65, 0.103);
  font-weight: 700;
  font-size: 1.1rem;
  display: flex;
}

.content-wrapper {
  .floating-container {
    left: 50%;
    transform: translate(-50%, 0);
    position: fixed;
    z-index: 101;
    bottom: 0;
    & .list {
      margin-bottom: calc(70px + env(safe-area-inset-bottom));
    }
    & .map {
      @media ($mobile) and (orientation: landscape) {
        margin-bottom: 10px;
      }
      margin-bottom: 20px;
    }
  }

  @media ($desktop) {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
  }
}

.popup-content {
  font-size: 0.9rem;
  padding: 16px;
  font-weight: 400;
  line-height: 150%;

  @media ($mobile) {
    text-align: center;
  }

  &-title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 8px;
  }
}

.onboarding-wrapper {
  margin-top: 20px;
}

.onboarding-fade-grow-animation {
  animation: fade-grow-in 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation-delay: 0.6s;
}

.adjust-wishes {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0 48px;

  @media ($desktop) {
    margin-top: 32px;
    text-align: center;
  }
}

.adjust-wishes-action {
  margin-bottom: 24px;
  max-width: 600px;
  line-height: 1.5;
  margin-top: 0;
}

.info-banner {
  margin-top: 20px;
}

.spinner-container {
  transform: translate(-50%, -50%);
  height: 50px;
  width: 50px;
}

@keyframes fade-grow-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
    transform-origin: 50% 0%;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
</style>
