<template>
  <div class="labels">
    <div v-for="infoItem in infoLabels" :key="infoItem" class="label">
      {{ infoItem }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoLabels',

  props: {
    swap: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      infoLabelConditions: [],
      infoLabels: []
    };
  },

  mounted() {
    this.infoLabelConditions = [
      {
        condition: this.swap.inSwapProcess,
        label: this.$t('proposition_card_in_swap_process'),
        priority: 1
      },
      {
        condition: this.swap.details && this.swap.fromBoost,
        label:
          this.swap.details && this.swap.details.fullSwapSince
            ? this.$t('proposition_card_full_int_boost')
            : this.$t('proposition_card_int_boost'),
        priority: 2
      },
      {
        condition:
          this.swap.details &&
          this.swap.details.percentage === 100 &&
          !this.swap.fromBoost,
        label: this.$t('proposition_card_full_interest'),
        priority: 3
      },
      {
        condition: this.swap.diff,
        label: this.$t('proposition_card_not_matching'),
        priority: 4
      }
    ];

    this.handleInfoLabels();
  },

  methods: {
    handleInfoLabels() {
      // ONLY RENDER ONE LABEL AT A TIME - FIND THE FIRST CONDITION THAT IS TRUE BASED ON PRIORITY
      const firstValidCondition = this.infoLabelConditions.find(
        cond => cond.condition
      );

      if (firstValidCondition) {
        this.infoLabels.push(firstValidCondition.label);
      }

      return this.infoLabels;
    }
  }
};
</script>

<style lang="scss" scoped>
.labels {
  display: flex;
}

.label {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 16px;
  background-color: #2c3e50b7;
  color: #fff;
  font-size: 11px;
  line-height: 10px;
  font-weight: 600;
  white-space: nowrap;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
}
</style>
