<template>
  <div
    id="app"
    class="app"
    :class="[
      { 'is-authenticated': isAuthenticated },
      { 'is-not-authenticated': !isAuthenticated },
      { 'is-freemium': primaryProposition.isFreemium },
      { 'is-trial': primaryProposition.isTrial },
      {
        'is-limited':
          primaryProposition.isFreemium || primaryProposition.isTrial
      }
    ]"
  >
    <DesktopHeader v-if="!isMobile && showDesktopHeader && !isAppRequest" />

    <MobileHeader
      v-if="isMobile && showMobileHeader && !isAppRequest"
      :is-authenticated="isAuthenticated"
    />

    <BoostBanner
      v-if="
        primaryProposition.extraVisibility && !primaryPropositionIsPremiumOrFree
      "
      class="boost-banner-app"
    />

    <TrialBanner v-if="trialBannerOpen && !isAppRequest" />

    <FreemiumBanner
      v-if="
        conversionBannerOpen && primaryProposition.isFreemium && !isAppRequest
      "
      :style="{ marginTop: freemiumBannerTopMargin }"
    />

    <MobileMenu v-if="menuOpen && isMobile && !isAppRequest" />

    <transition :name="transitionName" :mode="transitionMode">
      <router-view class="content" />
    </transition>

    <TabBar
      v-if="isAuthenticated && appReady && showMobileTabbar && !isAppRequest"
    />

    <Toast />

    <ImpersonateBanner v-if="isImpersonated && !isMobile"></ImpersonateBanner>

    <NotificationCenterContainer v-if="notificationCenterOpen && isMobile" />

    <Overlay v-if="showOverlay" />

    <CookieModal @accepted-all-cookies="initGtm" />

    <PageModalContainer />

    <StandardModal
      v-if="isVisible && proposition"
      standard-modal-name="ExtraVisibilityModal"
      :no-shadow="true"
      :locked="!!$route.query.q"
      @close="setExtraVisibilityPaymentVisible(false)"
    >
      <ExtraVisibility slot="content" :proposition="proposition" />
    </StandardModal>

    <GlobalModalsContainer v-if="showGlobalModal" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import Overlay from './components/MobileMenu/Overlay';
import DesktopHeader from './components/DesktopHeader/DesktopHeader';
import MobileMenu from './components/MobileMenu/MobileMenu';
import MobileHeader from './components/MobileMenu/MobileHeader';
import TabBar from './components/TabBar';
import TrialBanner from './components/PaymentBanners/TrialBanner';
import FreemiumBanner from './components/PaymentBanners/FreemiumBanner';
import Toast from './components/Modals/Toast';
import NotificationCenterContainer from '@/components/NotificationCenter/NotificationCenterContainer';
import CookieModal from './components/CookieModal';
import StandardModal from '@/components/Modals/Standard';
import ExtraVisibility from '@/components/Payments/ExtraVisibility';
import GlobalModalsContainer from '@/components/GlobalModalsContainer';
import BoostBanner from '@/components/Banners/BoostBanner';
import ImpersonateBanner from '@/components/Banners/ImpersonateBanner.vue';
import PageModalContainer from '@/components/PageModalContainer';

import checkAppReady from './store/checkAppReady';

import './scss/_global.scss';
import { addDataLayer } from '@/utils/gtm';
import { getHash256 } from './utils/helpers/hash';

const DEFAULT_TRANSITION = 'fade';
const DEFAULT_TRANSITION_MODE = 'out-in';

export default {
  name: 'App',
  components: {
    Overlay,
    DesktopHeader,
    MobileMenu,
    MobileHeader,
    TabBar,
    TrialBanner,
    FreemiumBanner,
    Toast,
    NotificationCenterContainer,
    CookieModal,
    StandardModal,
    ExtraVisibility,
    GlobalModalsContainer,
    BoostBanner,
    ImpersonateBanner,
    PageModalContainer
  },

  data() {
    return {
      transitionName: DEFAULT_TRANSITION,
      transitionMode: DEFAULT_TRANSITION_MODE
    };
  },

  computed: {
    ...mapGetters({
      showGlobalModal: 'ui/showGlobalModal',
      appReady: 'app/ready',
      user: 'app/user',
      isImpersonated: 'app/isImpersonated',
      isMobile: 'screenSize/isMobile',
      userPropositions: 'myPropositions/propositions',
      accountType: 'myPropositions/accountType',
      totalImageCount: 'myPropositions/totalImageCount',
      showMobileHeader: 'ui/showMobileHeader',
      showDesktopHeader: 'ui/showDesktopHeader',
      showOverlay: 'overlay/showOverlay',
      menuOpen: 'menu/isMenuOpen',
      trialBannerOpen: 'trial/trialBannerOpen',
      conversionBannerOpen: 'trial/conversionBannerOpen',
      tabBarVisible: 'menu/tabBarVisible',
      isAuthenticated: 'app/isAuthenticated',
      isAppRequest: 'app/isAppRequest',
      disableScroll: 'ui/disableScroll',
      notificationCenterOpen: 'menu/isNotificationCenterOpen',
      primaryProposition: 'myPropositions/primaryProposition',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryPropositionIsPremiumOrFree:
        'myPropositions/primaryPropositionIsPremiumOrFree',
      freemiumBannerTopMargin: 'ui/freemiumBannerTopMargin',
      newMatch: 'matches/newMatch'
    }),

    ...mapState('extraVisibility', ['isVisible', 'proposition']),

    showMobileTabbar() {
      const shouldShow = this.isMobile && this.tabBarVisible;
      return shouldShow;
    }
  },

  watch: {
    disableScroll() {
      document.body.style.overflow = this.disableScroll ? 'hidden' : '';
    },

    newMatch(event) {
      // Show full swap modal only if the event was triggered by the current user
      if (event && event.triggeredById === parseInt(this.user?.userId)) {
        this.setGlobalModal('FullSwapModal');
      }
    }
  },

  async mounted() {
    this.init();
    this.checkScreenSize();
    this.initTracking();

    if (this.isMobile) {
      document.addEventListener(
        'visibilitychange',
        this.handleVisibilityChanged
      );
    }
  },

  created() {
    window.addEventListener('resize', this.checkScreenSize);

    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;
      let transitionMode = 'out-in';

      if (transitionName === 'slide-over') {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        // Skip slide over right so not to mess with native mobile browsers behaviour
        transitionName = toDepth < fromDepth ? '' : 'slide-over-left';
        transitionMode = '';
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;
      this.transitionMode = transitionMode;
      next();
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChanged
    );
  },

  methods: {
    ...mapMutations({
      setExtraVisibilityPaymentVisible: 'extraVisibility/setVisible',
      setGlobalModal: 'ui/setGlobalModal'
    }),

    ...mapActions({
      initAppData: 'app/initAppData',
      initTracking: 'tracking/initTracking',
      startMixpanelSession: 'gtm/startMixpanelSession',
      checkScreenSize: 'screenSize/checkScreenSize'
    }),

    handleVisibilityChanged() {
      if (!this.primaryPropositionId) {
        return;
      }

      try {
        const visibilityState =
          document.visibilityState ||
          document.webkitVisibilityState ||
          document.mozVisibilityState ||
          document.msVisibilityState;

        if (visibilityState === 'visible') {
          this.$nextTick(() => {
            this.$store.dispatch(
              'handleSubscription/refreshStates',
              this.primaryPropositionId,
              {
                root: true
              }
            );
          });
        }
      } catch (e) {
        console.log(e.message);
      }
    },

    async init() {
      // Init default consent
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag('consent', 'default', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'denied'
      });

      await this.initAppData();
    },

    async initGtm() {
      try {
        this.initGTracking();
      } catch (error) {
        console.error(error);
      }
    },

    async initGTracking() {
      await checkAppReady();
      let dataLayerObj = {
        category: 'app',
        action: 'first_load',
        isAuthenticated: this.isAuthenticated ? 1 : 0,
        isMobileUser: this.isMobile
      };

      if (this.isAuthenticated) {
        const active = this.userPropositions.filter(x => x.active);

        dataLayerObj = {
          email: getHash256(this.user.email),
          userId: this.user.userId,
          userCreatedAt: this.user.registeredAt,
          userActiveAds: active.length,
          userNoAdPictures: this.totalImageCount,
          accountType: this.accountType,
          inSwapProcess: active.some(x => x.isSwapProcess),
          isTrial: this.trialBannerOpen,
          ...dataLayerObj
        };
      }
      if (this.isAuthenticated) {
        this.startMixpanelSession(dataLayerObj);
      }
      addDataLayer(dataLayerObj);
    }
  }
};
</script>

<style lang="scss" scoped>
.app {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $text-primary;
  font-family: Averta, sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  position: static;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.boost-banner-app {
  @media ($desktop) {
    position: relative;
    top: 80px;
  }
}

.content {
  padding: 0 0 58px 0;
  transition: padding-top 0.2s ease-in-out;

  @media ($desktop) {
    @include content();
    padding-top: $content-padding-top;
  }
}
</style>
