<template>
  <div class="my-propositions-card">
    <Spinner v-if="proposition.loading" />
    <div v-if="proposition.pausedAt !== null" class="pause-overlay">
      <div class="pause-title">
        {{
          isActivating
            ? $t('my_propositions_card_activating')
            : $t('my_propositions_card_ad_paused')
        }}
      </div>
      <div v-if="!isActivating" class="pause-subtitle">
        {{ $t('my_propositions_card_pause_subtitle') }}
      </div>
      <PrimaryButton
        v-if="!isActivating"
        @click.native="handleResumeProposition"
        >{{ $t('my_propositions_card_action_activate') }}</PrimaryButton
      >
    </div>
    <transition name="fade" mode="out-in">
      <ConfirmModal
        v-if="showConfirmDelete"
        :no-callback="closeDeleteModal"
        :yes-callback="() => handleDelete(proposition.propositionId)"
        :confirm-text="`${$t('my_propositions_card_delete_ad_question')} ${
          proposition.residences[0].streetAddress
        }?`"
        :info-text="$t('my_propositions_card_delete_ad_text')"
      />
    </transition>

    <router-link
      v-for="(residence, index) in proposition.residences"
      :key="residence.streetAddress + proposition.propositionId + index"
      class="card-content"
      :to="`${$t('path_proposition', $routeLocale)}/${
        proposition.propositionId
      }`"
    >
      <div class="card-presentation">
        <div
          class="img"
          :class="[
            residence.images && residence.images.length <= 0
              ? 'fallback-img'
              : ''
          ]"
          :style="[
            residence.images && residence.images.length
              ? {
                  backgroundImage: `url(${residence.images[0].fileName})`,
                  opacity: 1
                }
              : ''
          ]"
        />
        <div class="card-info">
          <div>
            <p class="primary-text">{{ residence.streetAddress }}</p>
            <p class="secondary-text">{{ getArea(residence) }}</p>
            <div
              v-if="
                isPrimaryProposition &&
                activePropositions.length > 1 &&
                proposition.active
              "
              class="using-as-indicator"
            >
              {{ $t('my_propositions_card_using_as') }}
            </div>
          </div>
          <p v-if="!isMobile" class="secondary-text">
            {{ $t('my_propositions_card_ad_num') }}
            {{ proposition.propositionNo }}
          </p>
          <p
            v-if="proposition.residences.length === index + 1"
            class="secondary-text"
          >
            {{ $t('my_propositions_card_created') }} {{ proposition.createdAt }}
          </p>
        </div>
      </div>
    </router-link>

    <div
      v-if="
        $country.isFeatureEnabled('EXTRA_VISIBILITY') &&
        proposition.active &&
        !proposition.isFreemium &&
        !proposition.isTrial &&
        !proposition.extraVisibility
      "
      class="sponsored-propositions-cta"
    >
      <div class="text-content">
        <h4>{{ $t('my_prop_card_boost_title') }}</h4>
        <p>
          {{ $t('my_prop_card_boost_text_variant') }}
        </p>
      </div>
      <BaseButton
        icon-file="icon-rocket-white"
        class="purple smaller cta-button"
        @click.stop="
          setExtraVisibilityPaymentProposition(proposition);
          setExtraVisibilityPaymentVisible(true);
        "
        >{{ $t('my_prop_card_boost_button') }}</BaseButton
      >
    </div>
    <div
      v-else-if="proposition.active && proposition.extraVisibility"
      class="active-until"
    >
      <BaseIcon icon-file="icon-rocket-purple" class="active-until-icon" />
      {{ $t('boost_banner_active_until') }}
      {{ $dayjs.tz(proposition.extraVisibility.endDate).format('D MMMM') }}
    </div>

    <div class="card-options">
      <BaseButton
        class="card-option-button smaller"
        :link="`${$t('path_edit_proposition', $routeLocale)}/${
          proposition.propositionId
        }`"
        >{{ $t('my_propositions_card_edit') }}</BaseButton
      >

      <div class="cta-btns-container">
        <router-link
          v-if="
            (proposition.active &&
              !proposition.isTrial &&
              !proposition.isFreemium) ||
            (proposition.active &&
              proposition.isFreemium &&
              !newPaywallsABTest) ||
            (proposition.active && proposition.isTrial && !newPaywallsABTest)
          "
          class="card-option flex-end-desktop"
          :to="`${$t('path_my_visitors', $routeLocale)}/${
            proposition.propositionId
          }`"
        >
          {{ $t('my_propositions_card_visitors') }}
        </router-link>
        <div
          v-else-if="
            (proposition.active &&
              proposition.isFreemium &&
              newPaywallsABTest) ||
            (proposition.active && proposition.isTrial && newPaywallsABTest)
          "
          class="card-option flex-end-desktop"
          @click="handleShowMyVisitorsPaywall"
        >
          {{ $t('my_propositions_card_visitors') }}
        </div>
        <router-link
          v-if="proposition.active"
          class="card-option"
          :to="`${$t('path_handle_subscription', $routeLocale)}/${
            proposition.propositionId
          }`"
        >
          {{ $t('my_propositions_card_subscription') }}
        </router-link>
      </div>

      <button
        v-if="!proposition.active && !paymentActive"
        class="card-option smaller warning flex-end-desktop"
        :warn="true"
        @click="showDeleteModal"
      >
        {{ $t('my_propositions_card_delete') }}
      </button>
      <button
        v-if="
          !proposition.active &&
          !paymentActive &&
          !proposition.freemiumAvailable
        "
        class="card-option smaller success"
        @click="
          () => {
            events.emitEvent(events.eventTypes.CLICK, 'Payment', {
              id: 'my-propositions-card'
            });

            routeToPath(
              `/${
                proposition.inFreeArea
                  ? $t('my_propositions_card_my_account')
                  : $t('my_propositions_card_payment')
              }/${proposition.propositionId}`
            );
          }
        "
      >
        {{ $t('pay_proposition_activate') }}
      </button>
      <button
        v-else-if="
          !proposition.active && !paymentActive && proposition.freemiumAvailable
        "
        class="card-option smaller success"
        @click="showFreemiumCtaModal(proposition.propositionId)"
      >
        {{ $t('pay_proposition_activate') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import PrimaryButton from '../Buttons/Primary';
import ConfirmModal from '../Modals/ConfirmModal';
import Spinner from '../Loading/Spinner';
import events from '../../utils/helpers/events';

export default {
  name: 'MyPropositionsCard',

  components: {
    ConfirmModal,
    PrimaryButton,
    Spinner
  },

  props: {
    proposition: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      events,
      showConfirmDelete: false,
      isActivating: false
    };
  },

  computed: {
    ...mapState('payment', ['activeOrders']),
    ...mapState('app', ['ready']),
    ...mapGetters({
      isTestEnabled: 'app/isTestEnabled',
      isMobile: 'screenSize/isMobile',
      primaryProposition: 'myPropositions/primaryProposition',
      primaryPropositionId: 'myPropositions/primaryPropositionId',
      primaryPropositionIsLimited: 'myPropositions/primaryPropositionIsLimited',
      primaryPropositionIsPremiumOrFree:
        'myPropositions/primaryPropositionIsPremiumOrFree',
      propositions: 'myPropositions/propositions'
    }),

    paymentActive() {
      return (
        this.activeOrders &&
        this.activeOrders.some(
          x => x.order.propositionId == this.proposition.propositionId
        )
      );
    },

    activePropositions() {
      if (!this.propositions) return [];
      return this.propositions.filter(proposition => proposition.active);
    },

    isPrimaryProposition() {
      return (
        this.primaryPropositionId &&
        this.primaryPropositionId.toString() ===
          this.proposition.propositionId.toString()
      );
    },

    newPaywallsABTest() {
      return this.$growthbook.isFeatureFlagEnabled('new-paywalls');
    }
  },

  created() {},

  methods: {
    ...mapMutations({
      setExtraVisibilityPaymentVisible: 'extraVisibility/setVisible',
      setExtraVisibilityPaymentProposition: 'extraVisibility/setProposition'
    }),

    ...mapActions({
      deleteProposition: 'myPropositions/deleteProposition',
      resumeSubscription: 'handleSubscription/resumeSubscription',
      fetchProducts: 'klarnaPayments/fetchProducts',
      setGlobalModal: 'ui/setGlobalModal'
    }),

    routeToPath(path) {
      this.$router.push(path);
    },

    getArea({ area, postalAddress }) {
      return `${area ? area : ''}${area && postalAddress ? ',' : ''} ${
        postalAddress ? postalAddress : ''
      }`;
    },

    showDeleteModal() {
      this.showConfirmDelete = true;
    },

    closeDeleteModal() {
      this.showConfirmDelete = false;
    },

    handleDelete(id) {
      this.deleteProposition({ propositionId: id });
      this.closeDeleteModal();
    },

    handleResumeProposition() {
      this.isActivating = true;
      this.resumeSubscription({
        propositionId: this.proposition.propositionId,
        reloadPropositions: true
      });
    },

    showFreemiumCtaModal(propositionId) {
      if (this.$route.query.propositionId != propositionId) {
        this.$router.replace({ query: { propositionId } });
      }

      this.fetchProducts({
        productType: 'annonsforlangning',
        propositionId: propositionId
      });
      this.setGlobalModal('ActivateOrPay');

      events.emitEvent(events.eventTypes.SEE, 'Paywall', {
        trigger: 'activate-proposition',
        src: 'my-propositions'
      });
    },

    handleShowMyVisitorsPaywall() {
      this.setGlobalModal('MyVisitorsLocked');

      events.emitEvent(events.eventTypes.SEE, 'Paywall', {
        trigger: 'my-visitors-locked',
        src: 'my-propositions'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.my-propositions-card {
  border-radius: 8px;
  box-shadow: 0px 6px 35px rgba(0, 0, 0, 0.09);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px 0;
  position: relative;
  user-select: none;
  width: 100%;
}

.primary-button {
  background-color: $transfer-blue;
  border-radius: 20px;
  color: white;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  height: 42px;
  line-height: 42px;
  padding: 0 30px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &--mobile {
    border-radius: 0;
    border-top: 1px solid rgba(151, 151, 151, 0.18);
    height: 50px;
    line-height: 50px;
    text-decoration: none;
    width: 100%;
  }

  @media (max-width: 1100px) {
    font-size: 12px;
    padding: 0 15px;
  }
}

.sponsored-propositions-cta {
  padding: 20px 35px;
  background-color: #f5e6dc;
  text-align: center;

  @media (min-width: 1210px) {
    padding-right: 15%;
    padding-left: 15%;
  }

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    align-items: center;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
  }

  p {
    font-size: 12px;
    line-height: 15px;
    margin: 0;
    margin-bottom: 16px;
  }
}

.cta-button {
  display: flex !important;
  justify-content: center !important;
  width: 100%;

  @media (max-width: 999px) {
    width: calc(100% - 30px);
    margin-left: 15px;
  }
}

.card-content {
  cursor: pointer;
  display: block;
  text-decoration: none;
  padding: 18px;
  width: 100%;

  @media ($mobile) {
    padding: 22px 12px;
    padding-top: 12px;
  }
}

.card-presentation {
  display: flex;
  position: relative;
}

.using-as-indicator {
  display: flex;
  align-items: center;
  color: $text-secondary;
  font-weight: 600;
  font-size: 11px;

  @media ($mobile) {
    margin-top: 7px;
  }

  @media ($desktop) {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 13px;
  }

  &::before {
    content: '';
    margin-top: -1px;
    margin-right: 3px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: $accent-green;

    @media ($desktop) {
      height: 10px;
      width: 10px;
    }
  }
}

.img {
  background-color: #d2d2d2;
  background-image: url('../../assets/svg/placeholder-img-small.svg');
  background-position: center;
  background-size: cover;
  border-radius: 2px;
  height: 138px;
  opacity: 0.6;
  width: 138px;

  @media ($mobile) {
    max-height: 75px;
    max-width: 75px;
    min-height: 75px;
    min-width: 75px;
  }

  &.fallback-img {
    background-color: #dddcdb;
    opacity: 1;
    background-position: 50%;
    background-size: 60%;
    aspect-ratio: 2 /0.9;
  }
}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;

  @media ($mobile) {
    padding: 0 10px;
  }
}

.reactivate-banner {
  align-items: center;
  border-top: 1px solid rgba(151, 151, 151, 0.18);
  cursor: pointer;
  display: flex;
  height: 80px;
  padding: 0px 18px;
  text-decoration: none;
  color: $text-primary;
  width: 100%;

  &:hover {
    background-color: rgba(151, 151, 151, 0.05);
  }
}

.chevron-right {
  margin-left: auto;
}

.reactivate-text {
  font-size: 14px;
  padding-left: 15px;

  > * {
    margin: 3px 0;
    padding: 0;
  }

  .reactivate-text-bold {
    font-weight: 700;
  }

  @media ($mobile) {
    p {
      font-size: 12px;
    }
  }
}

.cta-btns-container {
  display: flex;

  @media ($mobile) {
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  @media ($desktop) {
    gap: 20px;
  }
}

.card-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px solid rgba(151, 151, 151, 0.18);
  padding: 14px 12px;
  width: 100%;

  @media (min-width: 1000px) {
    align-items: center;
    padding: 14px 18px;
  }
}

.card-option {
  color: $transfer-blue;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;

  // &:last-of-type {
  //   margin-right: 0;
  // }

  @media ($mobile) {
    padding: 8px 0;
  }
}

.card-option-button {
  @media (max-width: 1000px) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.card-option.warning {
  color: red;
}

.card-option.success {
  color: green;
}

.flex-end-desktop {
  @media (min-width: 1000px) {
    margin-left: auto;
  }
}

.primary-text {
  color: $text-primary;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  padding: 0;

  @media ($mobile) {
    font-size: 16px;
  }
}

.secondary-text {
  color: $text-secondary;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 0;

  @media ($mobile) {
    font-size: 12px;
  }
}

.pause-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(256, 256, 256, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 60;
}

.pause-title {
  font-size: 1.3rem;
  font-weight: 600;
}

.pause-subtitle {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 20px;
}

.active-until {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5e6dc;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.active-until-icon {
  margin-right: 4px;
}
</style>
