<template>
  <transition name="fade-soft" mode="out-in" appear>
    <div v-if="show" class="account-notification-modal">
      <div class="content">
        <img
          src="../../assets/svg/teamwork-alt.svg"
          alt="Team effort, powerful statement"
        />
        <div class="text-wrapper">
          <div class="title">{{ $t('matches_notification_modal_title') }}</div>
          <div
            class="body-text"
            v-html="
              swapInterestCountsUnseenTotal === 1
                ? $t('matches_notification_modal_body_singular', {
                    swapInterestCountsUnseenTotal: swapInterestCountsUnseenTotal
                  })
                : $t('matches_notification_modal_body', {
                    swapInterestCountsUnseenTotal: swapInterestCountsUnseenTotal
                  })
            "
          />
          <div class="cta-button">
            <BaseButton
              :icon-file="
                isMobile ? 'icon-chat-white' : 'arrow-right-alt3-white'
              "
              :class="[
                'primary-action',
                'smaller',
                { rounded: isMobile, tiny: !isMobile }
              ]"
              :icon-last="!isMobile"
              :icon-size="isMobile ? 20 : 14"
              @click="$emit('cta-click')"
              >{{ $t('matches_notification_modal_cta') }}</BaseButton
            >
          </div>
        </div>
        <div class="close-button" @click="handleClose">
          <BaseIcon
            icon-file="icon-close-black"
            class="base-input-icon"
            :height="isMobile ? 20 : 16"
            :width="20"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AccountNotificationModal',

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      swapInterestCountsUnseenTotal: 'swapList/swapInterestCountsUnseenTotal',
      isMobile: 'screenSize/isMobile'
    })
  },

  methods: {
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss" scoped>
.account-notification-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0b182c;
  width: 100%;
  height: 100%;
  min-width: 360px;

  .content {
    display: flex;
    padding: 16px 0 16px 16px;
    margin: 16px;
    border-radius: 8px;
    border: 1px solid #e8e8e8;
    background: white;
    width: 100%;
    height: 100%;

    @media ($desktop) {
      padding: 16px 8px 16px 16px;
      margin: 16px 32px;
    }

    img {
      width: auto;
      height: 100%;
      margin: 8px 16px 8px 0;
      align-self: center;

      @media ($desktop) {
        display: none;
      }
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media ($desktop) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 12px;
      }
    }

    .title {
      color: #0b182c;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.18px;

      @media ($desktop) {
        display: none;
      }
    }

    .body-text {
      color: #0b182c;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.16px;

      @media ($desktop) {
        font-size: 14px;
      }
    }

    .cta-button {
      margin-top: 8px;
      @media ($desktop) {
        margin-top: 0;
        .tiny {
          border-radius: 8px;
          font-size: 13px;
          padding: 8px 14px;
        }
      }
    }

    .close-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      right: -10px;
      border-radius: 100%;
      margin-top: -25px;
      margin-left: auto;
      height: 24px;
      width: 24px;
      min-height: 24px;
      min-width: 24px;
      background: white;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
      cursor: pointer;

      @media ($desktop) {
        height: 20px;
        width: 20px;
        min-height: 20px;
        min-width: 20px;
        right: -18px;
        margin-top: -24px;
      }
    }
  }
}
</style>
