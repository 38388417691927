<template>
  <div
    ref="wrapper"
    class="wrapper"
    @mouseenter="isHovered = true"
    @touchstart="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div
      v-if="!isMobile && imageCount > 1"
      v-show="isHovered"
      class="desktop-btn-wrapper"
    >
      <div class="desktop-btn left" @click.prevent.stop="goToPrevImage" />
      <div class="desktop-btn right" @click.prevent.stop="goToNextImage" />
    </div>
    <div ref="slider" class="slides-container">
      <!-- last image, carousel back to last -->
      <div class="image-wrapper">
        <div
          v-if="imageCount > 1"
          :ref="`image-${imageCount + 1}`"
          :class="['image']"
          :style="[
            loadedImages[imageCount - 1]
              ? {
                  backgroundImage: `url(${loadedImages[imageCount - 1]})`,
                  opacity: 1,
                  maxWidth: `${imgWidth}px`,
                  minWidth: `${imgWidth}px`
                }
              : ''
          ]"
        />
      </div>

      <div
        v-for="(image, index) in loadedImages"
        :key="image + Math.random()"
        class="image-wrapper"
      >
        <div
          :ref="`image-${index + 1}`"
          :style="[
            image
              ? {
                  backgroundImage: `url(${image})`,
                  opacity: 1,
                  maxWidth: `${imgWidth}px`,
                  minWidth: `${imgWidth}px`
                }
              : ''
          ]"
          :class="[
            {
              'blurred-background-image': blurImage && index >= 2
            },
            'image'
          ]"
        />
        <div v-if="blurImage && index >= 2" class="overlay"></div>
        <div v-if="blurImage && index >= 2" class="blur-msg-wrapper">
          <div
            class="blur-msg-click-container"
            @click.prevent.stop="goToEditPropositionImages()"
          >
            <BaseIcon icon-file="blur-img-eye" :width="48" :height="46" />

            <div class="blur-msg">
              <p class="unblock-text">
                {{ $t('unlock_blur_msg') }}
              </p>
              <div class="upload-images-text">
                <span v-if="isLink">
                  <u>{{ $t('proposition_gallery_blur_link_msg') }}</u>
                </span>
                <span v-else>
                  {{ $t('proposition_gallery_blur_link_msg') }}
                </span>
                <span>
                  {{ $t('proposition_gallery_blur_msg') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- first image, carousel back to first -->
      <div class="image-wrapper">
        <div
          v-if="imageCount > 1"
          ref="image-0"
          :class="['image']"
          :style="[
            loadedImages[0]
              ? {
                  backgroundImage: `url(${loadedImages[0]})`,
                  opacity: 1,
                  maxWidth: `${imgWidth}px`,
                  minWidth: `${imgWidth}px`
                }
              : ''
          ]"
        />
      </div>
    </div>
    <div v-if="imageCount > 1" ref="dotsContainer" class="dots-container">
      <div
        v-for="index in imageCount"
        :key="`index-${index - 1}`"
        :ref="`dot-${index - 1}`"
        class="dot"
        :style="[
          index === 1 ? { backgroundColor: 'rgba(255, 255, 255, 0.9)' } : ''
        ]"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { lbPropositionApi } from '../../utils/axiosConfig';

export default {
  name: 'SwapListSlider',

  props: {
    primaryImage: {
      type: String,
      required: true
    },
    residenceIndex: {
      type: Number,
      default: 0
    },
    propositionId: {
      type: Number,
      required: true
    },
    imageCount: {
      type: Number,
      required: true
    },
    lockScroll: {
      type: Function,
      required: true
    },
    unlockScroll: {
      type: Function,
      required: true
    },
    proposition: {
      type: Object,
      default: () => {}
    },
    isLink: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      slideLocked: true,
      currentX: 0,
      startTouch: 0,
      initialTouch: 0,
      lastMovePosition: 0,
      scrollLocked: false,
      imgWidth: 0,
      isHovered: false,
      loadedImages: [],
      imagesInitialized: false
    };
  },

  computed: {
    ...mapGetters({
      isTestEnabled: 'app/isTestEnabled',
      isMobile: 'screenSize/isMobile',
      primaryProposition: 'myPropositions/primaryProposition',
      primaryPropositionId: 'myPropositions/primaryPropositionId'
    }),

    highDefImages() {
      const highRes = this.loadedImages.map(img =>
        img.replace('200x200', '800x800')
      );

      return highRes;
    },

    maxX() {
      const { imageCount } = this;
      const { wrapper } = this.$refs;

      const { clientWidth: wrapperWidth } = wrapper;

      let maxWidth = wrapperWidth * (imageCount + 1);

      return maxWidth;
    },

    directionVelocity() {
      // 30% shorter swipes required
      const { initialTouch, lastMovePosition, screenWidth } = this;

      const extraTravel = screenWidth * 0.3;

      return initialTouch - lastMovePosition > 0 ? extraTravel : -extraTravel;
    },

    screenWidth() {
      const { wrapper } = this.$refs;

      const { clientWidth: screenWidth } = wrapper;

      return screenWidth;
    },

    currentActive() {
      const { currentX, directionVelocity, screenWidth } = this;

      let currentActive = Math.round(
        (currentX + directionVelocity) / screenWidth
      );

      return currentActive;
    },

    currentActiveX() {
      const { screenWidth, currentActive } = this;

      return screenWidth * currentActive;
    },

    primaryPropositionImages() {
      if (
        !this.primaryProposition ||
        !this.primaryProposition.residences ||
        !this.primaryProposition.residences[0]
      ) {
        return [];
      }
      return this.primaryProposition.residences[0].images;
    },

    blurImage() {
      return this.primaryPropositionImages.length < 2;
    }
  },

  watch: {
    residenceIndex() {
      this.resetSlide();
    },

    async isHovered() {
      if (this.imagesInitialized) {
        return;
      }
      this.imagesInitialized = true;
      this.loadImages();
    }
  },

  mounted() {
    this.measeureContainer();
    this.createSlider();
  },

  methods: {
    async loadImages() {
      if (this.imageCount <= 1) {
        return;
      }

      const imagesRequest = await lbPropositionApi.get(
        `/propositions/${this.propositionId}/images/${this.residenceIndex + 1}`
      );

      const images = imagesRequest.data.map((image, index) => {
        let size = '200x200';
        if (index + 1 === this.currentActive) {
          size = '800x800';
        }
        return `${this.$t('url_residences', this.$routeLocale)}/${size}/${
          this.propositionId
        }/${image}`;
      });

      this.loadedImages = [
        this.loadedImages[0],
        ...images.splice(1, images.length)
      ];
    },
    upgradeToHighDef() {
      const { currentActive, loadedImages } = this;

      // Don't upgrade images not shown or the first image which should already be highDef
      if (currentActive === 0 || currentActive === this.imageCount + 1) {
        return;
      }

      if (!this.highDefImages) {
        return;
      }

      const highDefVersion =
        this.highDefImages[currentActive - 1] || this.highDefImages[0];

      const index =
        currentActive == '-0'
          ? 1
          : currentActive === -1
          ? 1
          : currentActive > this.imageCount
          ? this.imageCount
          : currentActive;

      const activeDiv = this.$refs[`image-${index}`];
      if (!activeDiv) {
        return;
      }

      const activeImageContainer = activeDiv[0];
      const image = new Image();

      // onload callback
      image.onload = function () {
        if (!activeImageContainer) {
          return;
        }

        activeImageContainer.style.backgroundImage = `url(${image.src})`;
        loadedImages[index - 1] = image.src;
      };
      image.src = highDefVersion;
    },

    measeureContainer() {
      const { wrapper } = this.$refs;

      if (!wrapper) return;

      const { clientWidth } = wrapper;

      this.imgWidth = clientWidth;
    },

    goToClosestImage() {
      const { imageCount } = this;
      if (imageCount <= 1) return;

      const { currentActiveX } = this;

      try {
        const { slider } = this.$refs;
        slider.style.transition = 'all 0.2s ease-in-out';
        slider.style.transform = `translateX(${-currentActiveX}px)`;
      } catch (error) {
        // Do nothing
      }

      this.startTouch = currentActiveX;
      this.currentX = currentActiveX;
      this.unlockScroll();
      this.handleDotSlide();
      this.upgradeToHighDef();

      this.checkIfShouldCarousel();
    },

    goToNextImage() {
      const { imageCount } = this;
      if (imageCount <= 1) return;

      const { screenWidth, currentX } = this;
      const { slider } = this.$refs;

      slider.style.transition = 'all 0.2s ease-in-out';

      slider.style.transform = `translateX(${-currentX - screenWidth}px)`;

      this.currentX = currentX + screenWidth;

      this.unlockScroll();
      this.handleDotSlide();
      this.upgradeToHighDef();
      this.checkIfShouldCarousel();
      this.handleDots();
    },

    goToPrevImage() {
      const { imageCount } = this;
      if (imageCount <= 1) return;

      const { currentX, screenWidth } = this;
      const { slider } = this.$refs;

      slider.style.transition = 'all 0.2s ease-in-out';

      slider.style.transform = `translateX(${-currentX + screenWidth}px)`;

      this.currentX = currentX - screenWidth;

      this.unlockScroll();
      this.handleDotSlide();
      this.upgradeToHighDef();
      this.checkIfShouldCarousel();
      this.handleDots();
    },

    handleDotSlide() {
      const { dotsContainer } = this.$refs;
      const { currentActive, screenWidth, imageCount } = this;

      let transitionLength;
      const dotWidth = 7 + 2; // width + margin

      if (currentActive === 0) {
        transitionLength = screenWidth / 2 - imageCount * dotWidth;
      } else if (currentActive === imageCount + 1) {
        transitionLength = screenWidth / 2;
      } else {
        transitionLength = screenWidth / 2 - currentActive * dotWidth;
      }

      if (dotsContainer) {
        dotsContainer.style.transform = `translateX(${transitionLength}px)`;
      }
    },

    checkIfShouldCarousel() {
      const { currentActive, imageCount, screenWidth } = this;
      const { slider } = this.$refs;

      if (currentActive > imageCount) {
        setTimeout(() => {
          slider.style.transition = '';
          slider.style.transform = `translateX(${-screenWidth}px)`;
          this.startTouch = screenWidth;
          this.currentX = screenWidth;
          this.upgradeToHighDef();
        }, 200);
      }

      if (currentActive === 0 || currentActive == '-0') {
        setTimeout(() => {
          slider.style.transition = '';
          slider.style.transform = `translateX(${-(
            screenWidth * imageCount
          )}px)`;
          this.startTouch = screenWidth * imageCount;
          this.currentX = screenWidth * imageCount;
          this.upgradeToHighDef();
        }, 200);
      }
    },

    handleDots() {
      const { imageCount, currentActive } = this;

      let realCurrentActive = currentActive - 1;

      if (currentActive === imageCount + 1) {
        realCurrentActive = 0;
      } else if (currentActive === 0) {
        realCurrentActive = imageCount - 1;
      }

      for (let i = 0; i < imageCount; i++) {
        if (this.$refs[`dot-${i}`][0]) {
          this.$refs[`dot-${i}`][0].style.backgroundColor =
            'rgba(255, 255, 255, 0.3)';
        }
      }

      if (this.$refs[`dot-${realCurrentActive}`]) {
        if (this.$refs[`dot-${realCurrentActive}`][0]) {
          this.$refs[`dot-${realCurrentActive}`][0].style.backgroundColor =
            'rgba(255, 255, 255, 0.9)';
        }
      }
    },

    handleSlideStart(event) {
      const { imageCount } = this;
      if (imageCount <= 1) return;

      const { slider } = this.$refs;
      if (slider) slider.style.transition = '';
      const startPosition = event.touches[0].screenX;
      this.initialTouch = startPosition;
      this.startTouch = startPosition + this.currentX;
    },

    resetSlide() {
      const url = `${this.$t('url_residences', this.$routeLocale)}/800x800/${
        this.propositionId
      }/${this.primaryImage}`;

      this.loadedImages = [url];
      const { slider } = this.$refs;
      const { imageCount, screenWidth } = this;
      slider.style.transition = '';

      if (imageCount <= 1) {
        slider.style.transform = `translateX(0px)`;
        this.currentX = 0;
        this.startTouch = 0;
        this.initialTouch = 0;
        this.lastMovePosition = 0;
      } else {
        slider.style.transform = `translateX(${-screenWidth}px)`;
        this.currentX = screenWidth;
        this.startTouch = screenWidth;
        this.initialTouch = screenWidth;
        this.lastMovePosition = screenWidth;
      }

      setTimeout(() => {
        this.handleDotSlide();
      }, 1000);
    },

    handleSlide(event) {
      const { imageCount } = this;
      if (imageCount <= 1) return;

      const currentPosition = event.touches[0].screenX;

      if (
        currentPosition > this.initialTouch + 20 ||
        currentPosition < this.initialTouch - 20
      ) {
        this.lockScroll();
      }

      const move = this.startTouch - currentPosition;
      this.lastMovePosition = currentPosition;

      if (move <= 0 || move >= this.maxX) return;
      const { slider } = this.$refs;

      this.currentX = move;

      this.handleDots();

      if (slider) slider.style.transform = `translateX(${-move}px)`;
    },

    createSlider() {
      const { slider, dotsContainer } = this.$refs;
      const {
        handleSlide,
        imageCount,
        goToClosestImage,
        handleSlideStart,
        screenWidth
      } = this;

      const url = `${this.$t('url_residences', this.$routeLocale)}/800x800/${
        this.propositionId
      }/${this.primaryImage}`;

      this.loadedImages = [url];

      if (dotsContainer) {
        if (dotsContainer)
          dotsContainer.style.transform = `translateX(${screenWidth / 2}px)`;

        setTimeout(() => {
          if (dotsContainer)
            dotsContainer.style.transition = 'all 0.2s ease-in-out';
        }, 500);
      }

      if (imageCount <= 1) {
        if (slider) slider.style.transform = `translateX(${0}px)`;
        this.startTouch = 0;
        this.currentX = 0;
      } else {
        if (slider) slider.style.transform = `translateX(${-screenWidth}px)`;
        this.startTouch = screenWidth;
        this.currentX = screenWidth;
      }

      if (slider) slider.addEventListener('touchstart', handleSlideStart);
      if (slider) slider.addEventListener('touchcancel', goToClosestImage);
      if (slider) slider.addEventListener('touchmove', handleSlide);
      if (slider) slider.addEventListener('touchend', goToClosestImage);
    },

    goToEditPropositionImages() {
      this.$emit('clickImage');
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  height: 100%;
  border-radius: $small-box-radius;

  overflow: hidden;
}

.desktop {
  &-btn {
    cursor: pointer;
    height: 30px;
    width: 30px;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 50%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
    background-image: url('../../assets/svg/arrow-dark.svg');
    background-position: center 8px;
    background-size: 50%;

    &.left {
      transform: rotate(90deg);
    }
    &.right {
      transform: rotate(-90deg);
    }
  }

  &-btn-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    z-index: 1;
  }
}

.slides-container {
  height: 100%;
  display: flex;
}

.image {
  position: relative;
  height: 100%;
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-size: cover;
  border-radius: 8px;

  // background-size: contain;
  z-index: 1;
  background-image: url('../../assets/svg/skyline-placeholder-img.svg');
}

.blurred-background-image {
  background-position: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  filter: blur(30px);
  -webkit-filter: blur(30px); /* Safari 6.0 - 9.0 */
  -moz-filter: blur(30px);
  -o-filter: blur(30px);
  -ms-filter: blur(30px);
  background-size: cover;
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.dots-container {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
}

.dot {
  height: 7px;
  width: 7px;
  min-width: 7px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0 2px;

  &.active {
    background-color: rgba(255, 255, 255, 0.9);
  }
}

.blur-msg-wrapper {
  position: absolute;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  @media ($desktop) {
    font-size: 0.85rem;
  }

  .blur-msg-click-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 40px;
    cursor: pointer;

    p {
      margin: 0;
    }
  }

  .blur-msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 7px;

    .unblock-text {
      font-weight: 600;
    }
    .upload-images-text {
      color: #fff;
      line-height: 150%;
    }
  }
}

.image-wrapper {
  position: relative;
}
</style>
